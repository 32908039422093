.custom-check-box{
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .custom--create, .custom--edit{
    border-bottom: 1px solid #d9d9d9;
    min-height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .custom--create{
    justify-content: flex-start;
  }
  .custom--edit{
    justify-content: space-between;
  }
  .ant-checkbox-wrapper{
    margin: 0 !important;
    word-break: break-all;
  }
}
.react-quill-custom{
  max-height: 250px;
  width: 100%;
  overflow: scroll;
}