@import '../node_modules/antd/lib/style/themes/default.less';
@import '../node_modules/antd/dist/antd.less';
@import './resources/less/antd.customize.less';
@import '../node_modules/react-quill/dist/quill.snow.css';

@import "./resources/font-awesome/all.css";
@import "./resources/less/common/variable";
@import "./resources/less/common/mixin";
@import "./resources/less/common/typography";
@import "./resources/less/common/common";
@import "./resources/less/common/layout";

/* CSS PAGE HERE */
@import "./resources/less/page/authentication";
@import "./resources/less/page/404";
@import "./resources/less/page/dashboard";
