@primary-color: #e8262d;
@link-color: #3498db;

.ant-modal-confirm-body {
    display: flex !important;
    align-items: center;

    .ant-modal-confirm-title {
        margin-left: 0.5rem !important;
    }
}

.ant-input-number,
.ant-input,
.ant-select-selector,
.ant-input-affix-wrapper,
.ant-picker {
    width: 100% !important;
    border-radius: 5px !important;
}

.ant-btn {
    border-radius: 5px;
}

.ant-tabs>.ant-tabs-nav {
    background-color: #fff;
    margin-bottom: 0;
}

.nav-space {
    .ant-tabs-nav {
        padding-left: 1rem !important;
    }
}
.nav-space-x {
    .ant-tabs-nav {
        margin: 0 0.75rem !important;
    }
}
.ant-card.ant-card-bordered {
    border-radius: 5px !important;
    border: none !important
}

.ant-card-body {
    padding: 20px !important;
}
.ant-card.custom-bottom{
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.ant-menu-root.ant-menu-vertical{
    border-right: none !important
}